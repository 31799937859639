import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import '../Styles/Stepper.css';
import '../Styles/Shared.css';
import {useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StepperSetup = ({currentStat, stepperStrings}) => {
  const [open, setOpen] = useState(false);
  // statuses stepper will display
  const steps = [
    {description: stepperStrings.preparingCustomizerText, label: 'Preparing Order'},
    {description: stepperStrings.readyToProduceText, label: 'Ready to Produce'},
    {description: stepperStrings.inProductionText, label: 'In Production'},
    {description: stepperStrings.productionCompleteText, label: 'Production Complete'},
  ];
  const [showStep1, setStep1] = useState(false);
  const [showStep2, setStep2] = useState(false);
  const [showStep3, setStep3] = useState(false);
  const [showStep4, setStep4] = useState(false);

  // determines which step will be active based on item's status
  let activeIndex;
  if (currentStat === 'Preparing Order') {
    activeIndex = 0;
  }
  if (currentStat === 'Ready to Produce') {
    activeIndex = 1;
  }
  if (currentStat === 'In Production') {
    activeIndex = 2;
  }
  if (currentStat === 'Production Complete') {
    activeIndex = 3;
  }

  // shows one tooltip at a time
  const handleClick = (label) => {
    if (label === 'Preparing Order') {
      setStep1(!showStep1);
      setStep2(false);
      setStep3(false);
      setStep4(false);
    }
    if (label === 'Ready to Produce') {
      setStep1(false);
      setStep2(!showStep2);
      setStep3(false);
      setStep4(false);
    }
    if (label === 'In Production') {
      setStep1(false);
      setStep2(false);
      setStep3(!showStep3);
      setStep4(false);
    }
    if (label === 'Production Complete') {
      setStep1(false);
      setStep2(false);
      setStep3(false);
      setStep4(!showStep4);
    }
  };

  const handleMessage = (index) => {
    // determines under which step the tooltip should show up based on its index
    return index === 0 ? (
      showStep1 ? (
        <p className="status-description">{stepperStrings.step1Text}</p>
      ) : null
    ) : index === 1 ? (
      showStep2 ? (
        <p className="status-description">{stepperStrings.step2Text}</p>
      ) : null
    ) : index === 2 ? (
      showStep3 ? (
        <p className="status-description">{stepperStrings.step3Text}</p>
      ) : null
    ) : index === 3 ? (
      showStep4 ? (
        <p className="status-description">{stepperStrings.step4Text}</p>
      ) : null
    ) : null;
  };

  const handleTooltipClose = () => {
    setOpen(false);
    setStep1(false);
    setStep2(false);
    setStep3(false);
    setStep4(false);
  };

  const stepStyles = {
    '& .Mui-completed': {
      '& .MuiSvgIcon-root': {
        opacity: 0.75,
      },
    },
    // active step will render significantly bigger than others
    '& .Mui-active': {
      '& .MuiSvgIcon-root': {
        fontSize: '55px',
      },
    },
    '& .Mui-disabled': {
      '& .MuiSvgIcon-root': {
        opacity: 0.75,
      },
    },
    '& .MuiStepConnector-line': {
      borderWidth: '2px',
    },
  };

  return (
    <>
      <Container component="main" id="stepper-container">
        {/* activeIndex tells the stepper to highlight all steps up to status matching the index in the steps array */}
        <Stepper className="stepper" activeStep={activeIndex} alternativeLabel sx={stepStyles} orientation="horizontal">
          {steps.map((steps, index) => (
            <Step key={steps.label}>
              <StepLabel sx={stepStyles}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableHoverListener
                  disableTouchListener
                  title={handleMessage(index)}
                >
                  {/* Button allowing customer to view tooltip */}
                  <Button
                    color="primary"
                    endIcon={<InfoOutlinedIcon className="icon" />}
                    className={
                      steps.label === steps[activeIndex]
                        ? 'tertiary-button-text active-override'
                        : 'tertiary-button-text blue-color-override'
                    }
                    onClick={() => {
                      setOpen(true);
                      handleClick(steps.label);
                    }}
                  >
                    {steps.description}
                  </Button>
                </Tooltip>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
    </>
  );
};
export default StepperSetup;
