import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import * as React from "react";
import fetch from "node-fetch";
import Form from "./Components/Form";
import TrackerSetup from "./Components/TrackerSetup";
import ErrorMessage from "./Components/ErrorMessage";
import Interstitial from "./Components/Interstitial";
import "./Styles/App.css";
import "./Styles/Shared.css";

const App = (props) => {
  const controller = new AbortController();
  const signal = controller.signal;

  // UseStates will store each entry contentful
  const [backendFormStrings, setFormStrings] = useState([]);
  const [backendInterStrings, setInterStrings] = useState([]);
  const [backendOrderItemStrings, setOrderItemStrings] = useState([]);
  const [backendStepperStrings, setStepperStrings] = useState([]);
  const [backendTrackerStrings, setTrackerStrings] = useState([]);
  const [clicked, setClick] = useState(false);
  const [error, setError] = useState(false);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#002b45",
      },

      secondary: {
        main: "#55555",
      },
    },
  });
  const [email, setEmail] = useState("");
  const [inter, setInter] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [orderArray, setArray] = useState([]);
  const [orderNum, setOrderNum] = useState("");
  const [payload, setPayload] = useState({});
  const [skipForm, setSkip] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [validOrderNum, setValidOrderNum] = useState(true);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParamsObj = {
    locale: urlParams.has("locale")
      ? decodeURIComponent(urlParams.get("locale"))
      : "en-US",
    email: urlParams.has("email")
      ? decodeURIComponent(urlParams.get("email"))
      : null,
    orderNumber: urlParams.has("orderNum")
      ? decodeURIComponent(urlParams.get("orderNum"))
      : null,
    hideHeaderFooter:
      decodeURIComponent(urlParams.get("hideHeaderFooter")) === "true",
  };

  // fetching the GET route from the Express server which matches the GET route from server.js
  const callBackendAPI = async () => {
    // use url encoded query parameters because React native does not support URLSearchParams
    const params = `email_id=${encodeURIComponent(
      email
    )}&order_id=${encodeURIComponent(orderNum)}`;
    // console.log(params);
    // 4000 milliseconds (aka 4 second) timeout
    const timeout = setTimeout(() => {
      controller.abort();
    }, 4000);
    let data = await fetch(`/order-status?${params}`, { signal })
      .then((response) => response.json())
      .then((responseJson) => responseJson.data)
      .catch((err) => {
        // const errMsg =
        //   err.name === "AbortError"
        //     ? "2 minute timeout exceeded."
        //     : err.message;
        // const msg = `frontend.App.callBackendAPI fetch order/status request ${email} ${orderNum} failed - ${errMsg}`;
        // console.debug(msg);
        setError(true);
      })
      .finally(() => {
        clearTimeout(timeout);
      });
    if (!data) {
      data = {
        CREATION_DATE: new Date().getDate(),
        SFCC_ORDER_ID: orderNum,
        EMAIL_ID: email,
        ORDER_ITEMS_ARRAY: [],
        error: { message: "ORDER NOT FOUND" },
      };
    }
    // console.log("order status data:");
    // console.log(JSON.stringify(data));
    setPayload(data);
    if (Array.isArray(data.ORDER_ITEMS_ARRAY)) {
      setArray(data.ORDER_ITEMS_ARRAY);
    } else {
      setArray(JSON.parse(data.ORDER_ITEMS_ARRAY));
    }

    const { error = undefined } = data;
    if (error) {
      setLocationError(true);
    }
    return data;
  };

  async function callStrings() {
    // use url encoded query parameters because React native does not support URLSearchParams
    const params = `locale=${encodeURIComponent(urlParamsObj.locale)}`;
    // 30000 milliseconds (aka 30 seconds) timeout
    const timeout = setTimeout(() => {
      controller.abort();
    }, 30000);
    const response = await fetch(`/contentful?${params}`, { signal })
      .then((res) => res)
      .catch((err) => {
        const errMsg =
          err.name === "AbortError"
            ? "30 second timeout exceeded."
            : err.message;
        const msg = `frontend.App.callStrings fetch contentful request failed - ${errMsg}`;
        console.debug(msg);
      })
      .finally(() => {
        clearTimeout(timeout);
      });
    if (!response.ok) {
      setError(true);
      const txt = await response.text();
      // console.debug(encodeURIComponent(txt));
      throw Error(
        "frontend.App.callStrings fetch contentful request response.ok false - " +
          txt
      );
    }
    const data = await response.json();
    // console.debug("contentful data:");
    // console.debug(JSON.stringify(data));
    const allEntries = data.items;

    // organizes each entry in their respected useState once express returns the expected payload
    for (
      let i = 0;
      i <= allEntries.length - 1 && allEntries.length !== 0;
      i++
    ) {
      if (allEntries[i]["fields"]["entry"] === "Form Text Fields") {
        setFormStrings(allEntries[i]["fields"]);
      }
      if (allEntries[i]["fields"]["entry"] === "Interstitial Text") {
        setInterStrings(allEntries[i]["fields"]);
      }
      if (allEntries[i]["fields"]["entry"] === "Tracker Setup Text") {
        setTrackerStrings(allEntries[i]["fields"]);
      }
      if (allEntries[i]["fields"]["entry"] === "Order Items Text") {
        setOrderItemStrings(allEntries[i]["fields"]);
      }
      if (allEntries[i]["fields"]["entry"] === "Stepper Setup Text") {
        setStepperStrings(allEntries[i]["fields"]);
      }
    }
    return data;
  }

  function checkEmail() {
    // must have an @ symbol, leading information pre-filling @ and domain name after @ with a .
    // eslint-disable-next-line
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.match(re)) {
      setValidEmail(true);
      return false;
    } else {
      setValidEmail(false);
      return true;
    }
  }

  function checkOrderNumber() {
    // order number must start with YUS followed by 3 zeroes followed by 9 numbers
    const re = /Y(US|CA)0{2}\d{10}$/;

    if (orderNum.match(re)) {
      setValidOrderNum(true);
      return false;
    } else {
      setValidOrderNum(false);
      return true;
    }
  }

  function cleanEmail(e) {
    e.preventDefault();
    // Removes spaces from data entered
    setEmail(e.target.value.replace(/\s/g, ""));
  }

  function cleanOrderNum(e) {
    e.preventDefault();
    // Removes spaces from data entered
    setOrderNum(e.target.value.replace(/\s/g, ""));
  }

  function componentDidMount() {
    callBackendAPI().catch((err) => console.log(err));
  }

  // Submit button handler
  const handleSubmit = (event) => {
    event.preventDefault();
    orderSearch();
  };

  function loadStrings() {
    callStrings().catch((err) => console.log(err));
  }

  // fetches order data
  const orderSearch = () => {
    let numFailedFields = 0;

    // resets validation
    setClick(true);
    setValidEmail(true);
    setValidOrderNum(true);

    if (checkEmail(email)) {
      setValidEmail(false);
      numFailedFields += 1;
    } else {
      setValidEmail(true);
    }

    if (checkOrderNumber(orderNum)) {
      setValidOrderNum(false);
      return false;
    } else {
      setValidOrderNum(true);
    }

    if (numFailedFields > 0) {
      setLocationError(false);
      return false;
    }

    componentDidMount();
    setInter(true);
  };

  // Interstitial timer
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInter(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [inter]);

  useEffect(() => {
    loadStrings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (urlParamsObj.email && urlParamsObj.orderNumber) {
      setSkip(true); // skip form input
      setEmail(urlParamsObj.email);
      setOrderNum(urlParamsObj.orderNumber);
      orderSearch();
    }

    if (urlParamsObj.hideHeaderFooter) {
      setHideHeaderFooter(true);
    }
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const dataObj = new Date(payload.CREATION_DATE);
  const orderDate = dataObj.toLocaleDateString(urlParamsObj.locale);
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* Header */}
      {!hideHeaderFooter && (
        <Box className="box-logo">
          <img
            alt="YETI"
            className="logo-img"
            src="https://yeti-web.imgix.net/40e82b9ad2cd04f9/original/YETI-Logo-White-200px.png"
          />
        </Box>
      )}
      <>
        {/* Checks if API has a status */}

        {payload.CUSTOM_ORDERS_PRODUCTION_STATUS ? (
          // checks for custom status
          payload.ORDER_CONTAINS_CUSTOM_PRODUCT === true ? (
            <>
              {inter ? (
                <Interstitial interStrings={backendInterStrings} />
              ) : (
                // If API has a status, the order has been found

                <Box className="TrackerContainer">
                  {/* Loads StepperSetup and additional information */}

                  <TrackerSetup
                    date={orderDate}
                    orderArray={orderArray}
                    trackerStrings={backendTrackerStrings}
                    orderStrings={backendOrderItemStrings}
                    stepperStrings={backendStepperStrings}
                    hideHeaderFooter={hideHeaderFooter}
                  />
                </Box>
              )}
            </>
          ) : (
            // Notification for when there's no custom order
            <>
              <Form
                handleSubmit={handleSubmit}
                cleanEmail={cleanEmail}
                cleanOrderNum={cleanOrderNum}
                checkEmail={checkEmail}
                checkOrderNum={checkOrderNumber}
                email={email}
                orderNum={orderNum}
                validEmail={validEmail}
                validOrderNum={validOrderNum}
                clicked={clicked}
                isCustom={payload.ORDER_CONTAINS_CUSTOM_PRODUCT}
                locationError={locationError}
                formStrings={backendFormStrings}
              />
            </>
          )
        ) : (
          <>
            {!skipForm && !error ? (
              <Form
                handleSubmit={handleSubmit}
                cleanEmail={cleanEmail}
                cleanOrderNum={cleanOrderNum}
                checkEmail={checkEmail}
                checkOrderNum={checkOrderNumber}
                email={email}
                orderNum={orderNum}
                validEmail={validEmail}
                validOrderNum={validOrderNum}
                clicked={clicked}
                isCustom={payload.ORDER_CONTAINS_CUSTOM_PRODUCT}
                locationError={locationError}
                formStrings={backendFormStrings}
              />
            ) : null}
          </>
        )}
        {error && <ErrorMessage />}
      </>
    </ThemeProvider>
  );
};

export default App;
