import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {CircularProgress} from '@mui/material';
import * as React from 'react';
import '../Styles/Interstitial.css';

const Interstitial = ({interStrings}) => {
  return (
    <Container component="inter-container" maxWidth="xs" id="container1">
      <Box className="inter-box">
        <CircularProgress className="loading" />
        <h1 className="inter-header">{interStrings.interHeader}</h1>
        <p className="inter-para">{interStrings.reminder}</p>
      </Box>
    </Container>
  );
};
export default Interstitial;
