import React from "react";
import Box from "@mui/material/Box";

const ErrorMessage = () => {
  return (
    <Box className="error-message">
      An unexpected error occurred, please try again later.
    </Box>
  );
};

export default ErrorMessage;
