import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../Styles/Form.css";
import "../Styles/Shared.css";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import { useState } from "react";
const Form = ({
  handleSubmit,
  cleanEmail,
  cleanOrderNum,
  email,
  orderNum,
  validEmail,
  validOrderNum,
  clicked,
  isCustom,
  locationError,
  checkEmail,
  checkOrderNum,
  formStrings,
}) => {
  const [openEmail, setEOpen] = useState(false);
  const [openNumber, setNOpen] = useState(false);
  const tooltipEmail = formStrings.tooltipEmailText;
  const tooltipNumber = formStrings.tooltipNumberText;
  // determining if text fields are empty
  let text = false;

  if (email.length > 0 && orderNum.length > 0) {
    text = true;
  }

  const handleTooltipEmail = () => {
    setEOpen(false);
  };

  const handleTooltipNumber = () => {
    setNOpen(false);
  };
  return (
    <Container component="main" maxWidth="xs" id="container1">
      <Box id="Form-box" className="box">
        {/* Header and short note */}
        <h1 className="form-header">{formStrings.formHeader}</h1>
        <p className="info">{formStrings.infoThankYouMessage}</p>

        {/* Email TextField */}
        <Box component="form" onSubmit={handleSubmit}>
          <label className="sub-header" id="tooltip-label">
            {formStrings.emailTextField}
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipEmail}
              open={openEmail}
              title={tooltipEmail}
            >
              <IconButton
                onClick={() => {
                  setEOpen(!openEmail);
                }}
              >
                <InfoOutlinedIcon className="icon" />
              </IconButton>
            </Tooltip>
          </label>

          <TextField
            fullWidth
            autoComplete="email"
            name="email"
            className="form-field"
            placeholder={formStrings.emailFieldPlaceholder}
            id="email"
            color="primary"
            onChange={cleanEmail}
            onBlur={checkEmail}
          />

          {/* checks if a submission has been made and email is valid */}
          {!validEmail ? (
            <p className="info error" id="form-error">
              {formStrings.emailFailedText}
            </p>
          ) : null}

          {/* Order Number TextField */}
          <label className="sub-header" id="tooltip-label">
            {formStrings.orderNumberTextField}
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipNumber}
              open={openNumber}
              title={tooltipNumber}
            >
              <IconButton
                onClick={() => {
                  setNOpen(!openNumber);
                }}
              >
                <InfoOutlinedIcon className="icon" />
              </IconButton>
            </Tooltip>
          </label>

          <TextField
            fullWidth
            id="order-number"
            className="form-field"
            placeholder={formStrings.orderNumberFieldPlaceholder}
            name="orderNumber"
            color="primary"
            onChange={cleanOrderNum}
            onBlur={checkOrderNum}
          />

          {/* checks if a submission has been made and order number is valid */}
          {!validOrderNum ? (
            <p className="info error" id="form-error">
              {formStrings.orderFailedText}
            </p>
          ) : null}

          {isCustom === false ? (
            // Notification for non-custom orders
            <Box className="location-error">
              <p>{formStrings.nonCustomOrder}</p>
            </Box>
          ) : // checks if a submission has been made,
          // at this point, both fields are valid,
          // API may be returning order not found
          clicked && validEmail && validOrderNum && locationError ? (
            // Prompts for new information when order not found
            <Box className="location-error">
              <p className="info error" id="form-error">
                {formStrings.locationError}
              </p>
            </Box>
          ) : null}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            className={
              text
                ? " primary-button button-text"
                : "primary-button button-text text-override"
            }
            disabled={!text}
          >
            <p className={text ? "button-text" : " button-text text-override"}>
              {formStrings.submitButton}
            </p>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default Form;
