import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Button, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import StepperSetup from './StepperSetup';
import OrderItems from './OrderItems';
import {useState, useEffect} from 'react';
import * as React from 'react';
import '../Styles/Tracker.css';
import '../Styles/Shared.css';

const TrackerSetup = ({date, orderArray, trackerStrings, orderStrings, stepperStrings, hideHeaderFooter}) => {
  const [neededStatuses, setNeededStatuses] = useState([]);
  const steps = ['Preparing Customizer', 'Ready to Produce', 'In Production', 'Production Complete'];
  const [toTop, setToTop] = useState(false);

  let cancelled = false;
  let defaultStatus = false;
  let notifyCancel = false;

  function handleBackButton(e) {
    e.preventDefault();
    window.location = window.location.origin;
  }

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 800) {
      setToTop(true);
    } else {
      setToTop(false);
    }
  };

  function handleScrollButton() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Determines if the order has rejected items
  orderArray.map((array) => {
    for (let i = 0; i < steps.length; i++) {
      // non cancelled custom items with statuses will be sorted based on their status in orderItems
      if (
        steps[i] !== array.CUSTOMIZATION_PRODUCTION_STATUS &&
        array.REJECTION_DESCRIPTION_CODE_LEVEL === 'Not Rejected' &&
        array.VARIATION === 'Custom' &&
        array.CUSTOMIZATION_PRODUCTION_STATUS !== null &&
        neededStatuses.indexOf(array.CUSTOMIZATION_PRODUCTION_STATUS) === -1
      ) {
        setNeededStatuses([...neededStatuses, array.CUSTOMIZATION_PRODUCTION_STATUS]);
      }
    }
    // items with null status will default to the prep. order status
    if (
      array.REJECTION_DESCRIPTION_CODE_LEVEL === 'Not Rejected' &&
      array.CUSTOMIZATION_PRODUCTION_STATUS === null &&
      array.VARIATION === 'Custom'
    ) {
      defaultStatus = true;
    }
    // cancelled items will be moved into a separate container
    if (array.REJECTION_DESCRIPTION_CODE_LEVEL !== 'Not Rejected') {
      cancelled = true;
      notifyCancel = true;
      return true;
    }
    return true;
  });

  return (
    <>
      {toTop ? (
        <IconButton disableRipple className="scrollListener" onClick={handleScrollButton}>
          <ArrowUpwardIcon />
        </IconButton>
      ) : null}

      <Button
        fullWidth
        onClick={handleBackButton}
        variant="contained"
        startIcon={<ArrowBackIcon />}
        sx={{mt: 3, mb: 2}}
        color="primary"
        className="primary-button"
        id="back-button"
        disableRipple
      >
        {trackerStrings.backButtonText}
      </Button>
      <Container component="main" maxWidth="xl" id="scrollListener" display="flex">
        <Box align="center" margin="2%" key={'one'}>
          <Box className="contain" id="tracker-header-container">
            <h1 className="header" id="tracker-header">
              {trackerStrings.trackerHeaderText}
            </h1>
            <p className="info" id="tracker-info">
              {trackerStrings.trackerHeaderInfo}
            </p>
          </Box>
          <>
            {notifyCancel ? (
              <Box className="contain">
                <p className="info error" id="cancelled-items-message" align="left">
                  {trackerStrings.cancelledMessage}
                </p>
              </Box>
            ) : null}
            {neededStatuses.map((statuses, index) => {
              return (
                <div key={index} className="order-container-wrapper">
                  <div className="order-container">
                    <StepperSetup currentStat={statuses} stepperStrings={stepperStrings} />
                    <OrderItems
                      estimatedShipDate={trackerStrings.estimatedShipDateText}
                      orderArray={orderArray}
                      date={date}
                      statuses={statuses}
                      orderDetailsHeader={trackerStrings.orderDetailsHeader}
                      orderStrings={orderStrings}
                    />
                  </div>
                </div>
              );
            })}

            {defaultStatus ? (
              <>
                <div className="order-container">
                  <StepperSetup currentStat={'Preparing Customizer'} stepperStrings={stepperStrings} />
                  <OrderItems
                    estimatedShipDate={trackerStrings.estimatedShipDateText}
                    orderArray={orderArray}
                    date={date}
                    statuses={null}
                    orderDetailsHeader={trackerStrings.orderDetailsHeader}
                    orderStrings={orderStrings}
                  />
                </div>
              </>
            ) : null}
            {cancelled ? (
              <div className="order-container">
                <OrderItems
                  estimatedShipDate={null}
                  orderArray={orderArray}
                  date={date}
                  statuses={null}
                  orderDetailsHeader={trackerStrings.cancelledDetailsHeader}
                  orderStrings={orderStrings}
                />
              </div>
            ) : null}
          </>
        </Box>
      </Container>
      { !hideHeaderFooter &&
        <Box className="footer">
            <p className="category white-color-override">{trackerStrings.faqTitle}</p>
            <Box className="footer-item-container">
              <a className="link" href={trackerStrings.footerItem1Url}>
                {trackerStrings.footerItem1Text}
              </a>
              <a className="link" href={trackerStrings.footerItem2Url}>
                {trackerStrings.footerItem2Text}
              </a>
            </Box>
        </Box>
      }
    </>
  );
};

export default TrackerSetup;
