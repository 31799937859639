import Box from '@mui/material/Box';
import * as React from 'react';
import '../Styles/Order.css';
import '../Styles/Shared.css';
import {Button} from '@mui/material';
import {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useRef} from 'react';

const OrderItems = ({orderArray, date, statuses, orderDetailsHeader, estimatedShipDate, orderStrings}) => {
  const buttonRef = useRef();
  const [buttonY, setY] = useState();
  const [currentIndex, setIndex] = useState(6); // will determine how many items can be displayed
  const [displayProduct, setDisplay] = useState(true);

  let count = 0; // will represent the total number of items used
  let index = 0;

  orderArray.map((array) => {
    // Counts the number of custom items in order
    if (
      array.VARIATION === 'Custom' &&
      (array.CUSTOMIZATION_PRODUCTION_STATUS === statuses ||
        (estimatedShipDate === null && array.REJECTION_DESCRIPTION_CODE_LEVEL !== 'Not Rejected'))
    ) {
      count++;
    }
    return true;
  });

  // position of view/hide more button
  const getPosition = () => {
    const y = buttonRef.current.offsetTop;
    return setY(y);
  };

  // will cause all items to be displayed
  const handleClick = () => {
    setIndex(count);
    getPosition();
    setDisplay(false);
  };

  // will cause first 6 items to be displayed
  const handleClose = () => {
    setIndex(6);
    setDisplay(true);
    // takes users back to view button's position
    window.scrollTo(0, buttonY - 400);
  };

  const increaseIndex = () => {
    index++;
  };

  return (
    <>
      <Box className="order-details-header">{orderDetailsHeader}</Box>
      <Box className="date-and-shipment-text">
        <>
          <p>
            {orderStrings.orderDate} {date}
          </p>
          {estimatedShipDate}
        </>
      </Box>
      <Box className="order-details-items" align="left">
        <>
          {orderArray.map((array, i) => {
            // returns appropriate items based on the status, state, and estimated ship date passed in orderItems
            if (
              (array.CUSTOMIZATION_PRODUCTION_STATUS === statuses && array.VARIATION === 'Custom') ||
              (estimatedShipDate === null &&
                array.VARIATION === 'Custom' &&
                array.REJECTION_DESCRIPTION_CODE_LEVEL !== 'Not Rejected')
            ) {
              return (
                <React.Fragment key={i}>
                  {/* keeps track of each item's index */}
                  {increaseIndex()}
                  {/* shows items up to specified amount */}
                  {index <= currentIndex ? (
                    <>
                      <Box className="product-card">
                        {array.REJECTION_DESCRIPTION_CODE_LEVEL !== 'Not Rejected' ? (
                          <Box className="cancelled-container">
                            <p className="error">{orderStrings.cancelled}</p>
                          </Box>
                        ) : null}

                        {array.PRODUCT_IMAGE_URL === 'NOT_SET' ? (
                          <Box className="no-image-container">
                            <p className="no-image">{array.DESCRIPTION}</p>
                          </Box>
                        ) : (
                          <img
                            alt={array.DESCRIPTION}
                            className="product-image display-inline"
                            src={array.PRODUCT_IMAGE_URL}
                            width="100"
                          />
                        )}

                        <Box className="product-name-container">
                          <p className="order-description">{array.DESCRIPTION}</p>
                          <p className="order-quantity">
                            {orderStrings.quantity}
                            {array.QUANTITY}
                          </p>
                        </Box>

                        {array.REJECTION_DESCRIPTION_CODE_LEVEL !== 'Not Rejected' ? (
                          <Box className="cancel-reason">
                            <p className="error">
                              {orderStrings.cancellationReason}
                              {array.REJECTION_DESCRIPTION_CODE_LEVEL}
                            </p>
                          </Box>
                        ) : null}
                      </Box>
                    </>
                  ) : null}
                </React.Fragment>
              );
            }
            return true;
          })}
        </>
      </Box>
      {/* option to display more shows up for orders with over 6 items */}
      {count > 6 ? (
        <Box className="toggle-view-box" ref={buttonRef}>
          <>
            {displayProduct ? (
              <Button
                className="tertiary-button-text blue-color-override"
                onClick={() => {
                  handleClick();
                }}
              >
                {orderStrings.viewMore}
                <KeyboardArrowDownIcon />
              </Button>
            ) : (
              <Button
                className="tertiary-button-text blue-color-override"
                onClick={() => {
                  handleClose();
                }}
              >
                {orderStrings.hide}
                <KeyboardArrowUpIcon />
              </Button>
            )}
          </>
        </Box>
      ) : null}
    </>
  );
};

export default OrderItems;
